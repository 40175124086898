.App {
  text-align: center;
}


/* styles for desktop devices */
@media only screen and (min-width: 992px) {
  .Profile-Pic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #ddd;
    border-radius: 50%;
    padding: 5px;
    max-width: 18%;
    max-height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .description-style {
    margin-top: 3.5vh;
    font-size: 2.5vw;
  }
}

/* styles for tablet devices */
@media only screen and (min-width: 700px) and (max-width: 991px) {
  .Profile-Pic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #ddd;
    border-radius: 50%;
    padding: 5px;
    max-width: 30%;
    max-height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

   .description-style {
    margin-top: 3.5vh;
    font-size: 3.5vw;
  }
}

/* styles for mobile devices */
@media only screen and (max-width: 699px) {
  .Profile-Pic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #ddd;
    border-radius: 50%;
    padding: 5px;
    max-width: 35%;
    max-height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .description-style {
    margin-top: 4.5vh;
    font-size: 5vw;
  }
}

.ButtonToTop{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 15px;
  cursor: pointer;
  color: #10255A;
  margin: 0.25vh;
}

.button-container {
  display: flex;
  justify-content: space-between;
}


.name-text {
  color: rgb(227, 89, 255);
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

